import { createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock($setup["NLayout"], null, {
    default: _withCtx(() => [
      _createVNode($setup["NLayoutHeader"], null, {
        default: _withCtx(() => [
          _createVNode($setup["TheHeader"])
        ]),
        _: 1
      }),
      _createVNode($setup["NLayout"], { "has-sider": "" }, {
        default: _withCtx(() => [
          _createVNode($setup["TheSidebar"]),
          _createVNode($setup["NLayout"], {
            class: "main-block",
            ref: "mainBlockRef",
            style: _normalizeStyle($setup.containerHeightOffset)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  (_openBlock(), _createBlock(_KeepAlive, { include: $setup.allPagesWithoutLayout }, [
                    ($setup.isActivated)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: undefined }))
                      : _createCommentVNode("", true)
                  ], 1032, ["include"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}